import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import 'prismjs/themes/prism-okaidia.css';

export default ({ children, post_title }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return (
    <div className="site-wrapper">
      <header className="site-header">
        <div className="site-title">
          <div className="site-title-inner">
            <span className="site-title-mark">></span>
            <span className="site-title-text">
              <Link to="/">{data.site.siteMetadata.title}</Link>
            </span>
          </div>
          {post_title ? 
            <div className="site-title-inner site-header-post-title">
              <span className="site-title-mark">/</span>
              <span className="site-title-text">{post_title}
              </span>
            </div>
          : null}
        </div>
      </header>
      {children}
      <footer className="site-footer">
        <p>&copy; {new Date().getFullYear()} hardworkers.dev</p>
      </footer>
    </div>
  )
}
